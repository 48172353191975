import Vue from 'vue'
import Vuex from 'vuex'

const axios = require('axios');
const clonedeep = require('lodash.clonedeep');

Vue.use(Vuex)

/*global Config*/

export default new Vuex.Store({
  state: {
    transcripts: {
      data: [],
      loaded: false,
      error: false,
    },
    recordings: {
      data: [],
      loaded: false,
      error: false,
    },
  },
  getters: {
    transcripts(state) {
      return state.transcripts.data;
    },
    transcriptsLoaded(state) {
      return state.transcripts.loaded;
    },
    transcriptsError(state) {
      return state.transcripts.error;
    },
    recordings(state) {
      return state.recordings.data;
    },
    recordingsLoaded(state) {
      return state.recordings.loaded;
    },
    recordingsError(state) {
      return state.recordings.error;
    },
  },
  mutations: {
    setTranscripts(state, list) {
      state.transcripts.data = list;
      state.transcripts.loaded = true;
      state.transcripts.error = false;
    },
    errorTranscripts(state) {
      state.transcripts.data = [];
      state.transcripts.loaded = false;
      state.transcripts.error = true;
    },
    renameTranscript(state, {hash, title}) {
      state.transcripts.data.forEach((item) => {
        if(item.hash == hash) item.title = title;
      });
    },
    setRecordings(state, list) {
      const oldRecordings = clonedeep(state.recordings.data);
      state.recordings.data = list;
      state.recordings.error = false;
      oldRecordings.forEach((item1) => {
        var found = false;
        state.recordings.data.forEach((item2) => {
          if(item1.hash == item2.hash) {
            found = true;
            item2.type = item1.type;
            item2.downloaded = item1.downloaded;
            item2.uploaded = item1.uploaded;
          }
        });
        if(!found) state.recordings.data.push(item1);
      });
      state.recordings.data.sort((a, b) => b.time - a.time);
    },
    finishRecordings(state) {
      state.recordings.loaded = true;
    },
    errorRecordings(state) {
      state.recordings.data = [];
      state.recordings.loaded = false;
      state.recordings.error = true;
    },
    renameRecording(state, {hash, title}) {
      state.recordings.data.forEach((item) => {
        if(item.hash == hash) item.title = title;
      });
    },
    removeRecording(state, {hash}) {
      for(let i = 0; i < state.recordings.data.length; i++) {
        if(state.recordings.data[i].hash == hash)
          state.recordings.data.splice(i, 1);
      }
    },
    uploadRecording(state, {hash}) {
      const item = state.recordings.data.find((item) => item.hash == hash);
      if(item) {
        item.uploaded = true;
        item.downloaded = false;
      }
    },
    downloadRecording(state, {hash}) {
      const item = state.recordings.data.find((item) => item.hash == hash);
      if(item) {
        item.uploaded = true;
        item.downloaded = true;
      }
    },
    addRecording(state, data) {
      for(let i = 0; i < state.recordings.data.length; i++) {
        if(state.recordings.data[i].hash == data.hash) {
          Vue.set(state.recordings.data, i, data);
          return;
        }
      }
      state.recordings.data.push(data);
      state.recordings.error = false;
      state.recordings.data.sort((a, b) => b.time - a.time);
    },
  },
  actions: {
    loadTranscripts({commit, dispatch}) {
      return axios.post(Config.API_URL + "/transcript/list").then((response) => {
        commit("setTranscripts", response.data.items);
        if(response.data.pending) window.setTimeout(() => dispatch("loadTranscripts"), 5000);
      }).catch((/*error*/) => {
        commit("errorTranscripts");
      });
    },
    renameTranscript({state, commit}, {hash, title}) {
      var oldTitle = title;
      state.transcripts.data.forEach((item) => {
        if(item.hash == hash) oldTitle = item.title;
      });
      commit("renameTranscript", {hash, title});
      return axios.post(Config.API_URL + "/transcript/rename/" + hash, {
        title
      }).catch((/*error*/) => {
        commit("renameTranscript", {hash, title: oldTitle});
      });
    },
    loadRecordings({commit}) {
      return axios.post(Config.API_URL + "/recording/list").then((response) => {
        commit("setRecordings", response.data.items);
        return response;
      }).catch((/*error*/) => {
        commit("errorRecordings");
      });
    },
    renameRecording({state, commit}, {hash, title}) {
      var oldTitle = title;
      state.recordings.data.forEach((item) => {
        if(item.hash == hash) oldTitle = item.title;
      });
      commit("renameRecording", {hash, title});
      return axios.post(Config.API_URL + "/recording/rename/" + hash, {
        title
      }).catch((/*error*/) => {
        commit("renameRecording", {hash, title: oldTitle});
      });
    },
    deleteRecording({commit}, {hash}) {
      return axios.post(Config.API_URL + "/recording/delete/" + hash, {
      }).then(() => {
        commit("removeRecording", {hash});
      });
    },
  },
})
