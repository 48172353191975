<template>
  <v-app>

    <v-navigation-drawer app v-model="drawer" :permanent="!$vuetify.breakpoint.xsOnly && !$vuetify.breakpoint.smOnly">

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Notuj.pl
          </v-list-item-title>
          <v-list-item-subtitle>
            aaa
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list>
        <v-list-item link href="#/">
          <v-list-item-icon>
            <v-icon>mdi-file-document</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ _('Twoje notatki') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link href="#/recordings">
          <v-list-item-icon>
            <v-icon>mdi-volume-high</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ _('Twoje nagrania') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link>
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ _('Twoje konto') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div class="nav-bottom">
        <v-list nav>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-logout-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ _('Wyloguj się') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>

    </v-navigation-drawer>

    <v-app-bar v-if="$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="d-block d-md-none">
      </v-app-bar-nav-icon>
    </v-app-bar>  

    <v-main>
      <v-container fluid>
        <slot/>
      </v-container>
    </v-main>

  </v-app>
</template>

<script>

export default {
  name: 'Page',
  data() {
    return {
      drawer: false,
    }
  },
}

</script>

<style>
.v-list-item--link .v-list-item__icon:first-child {
  margin-right: 8px;
}

.nav-bottom {
  position: absolute;
  bottom: 8px;
  left: 0;
  right: 0;
}
.nav-bottom .v-list {
  padding: 0 !important;
}
.nav-bottom .v-list-item {
  padding: 0 16px !important;
}
</style>
