import Vue from 'vue'
import VueRouter from 'vue-router'

import PageTranscripts from '../page/Transcripts.vue'
import PageRecordings from '../page/Recordings.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: PageTranscripts
  },
  {
    path: '/recordings',
    name: 'recordings',
    component: PageRecordings
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
