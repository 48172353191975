<template>
  <center class="mt-6">
    <div>
      <v-icon color="red" class="icon-error">mdi-emoticon-cry-outline</v-icon>
    </div>
    <p>
      {{ _('Niestety, mamy problem z załadowaniem Twoich danych.') }}
    </p>
    <v-btn @click.stop="$emit('click')">
      {{ _('Spróbuj ponownie') }}
    </v-btn>
  </center>
</template>

<script>

require('../assets/locales/pl.png');
require('../assets/locales/en.png');

export default {
  name: 'ComponentError',
}
</script>

<style>
I.icon-error::before {
  font-size: 200px;
}
</style>