<template>
  <span>
    <img src="../assets/locales/pl.png" class="locale-img" v-if="value=='pl'"/>
    <img src="../assets/locales/en.png" class="locale-img" v-if="value=='en'"/>
  </span>
</template>

<script>

require('../assets/locales/pl.png');
require('../assets/locales/en.png');

export default {
  name: 'ComponentFlag',
  props: {
    value: String,
  }
}
</script>

<style>
.locale-img {
  width: 24px;
  height: 18px;
  border: 1px solid #DDD;
  margin-right: 5px;
  vertical-align: -12.5%;
}
</style>